[data-aos="custom-fade-up"] {
    transform: translate(0, 80px);
    opacity: 0;
    transition-property: transform, opacity;
    transition: cubic-bezier(0,0,.2,1);
}

[data-aos="custom-fade-up"].aos-animate {
    transform: translate(0, 0);
    opacity: 1;
}