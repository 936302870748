@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

body {
  font-family: 'Prata',ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
  letter-spacing: 0.8px !important;
  background-color: #000 !important;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

