.info-section-wrap {
    margin-top: 80px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.info-section {
    width: 100%;
    padding: 51px 120px;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    z-index: 1;
}

.info-section__head {
    flex: 0 0 327px;
}
.info-section__head-title {
    font-size: 36px;
    line-height: 1.5;
    letter-spacing: 1.8px;
    font-weight: 400;
    color: #fff;
}
.info-section__head-contents {
    margin-top: 24px;
}
.info-section__head-subtitle {
    color: rgba(178, 178, 178);
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.7px;
}
.info-section__head-content {
    font-size: 18px;
    line-height: 2;
    letter-spacing: 0.7px;
}

.info-section__body {
    flex: 0 0 487px;
    border-left: 1px solid rgba(255, 255,255 ,0.2);
    display: flex;
    flex-direction: column;
}
.info-section__body-wrap {
    margin: 0 auto;
}
.info-section__body-title {
    color: #F2C055;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 2;
    margin-bottom: 0;
}
.info-section__body-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
    gap: 24px;
}

.time-item {
    display: flex;
    flex-direction: column;
}

.time-item__head {
    color: rgba(178, 178, 178);
    font-weight: 400;
    letter-spacing: 1.2px;
    font-size: 24px;
    line-height: 2;
}

.time-item__body {
    color: #fff;
    letter-spacing: 1.8px;
    font-size: 36px;
    line-height: 1.2;
}

.time-item__footer {
    font-weight: 400;
    letter-spacing: 0.7px;
    font-size: 14px;
    line-height: 2;
}

.info-section__footer {
    flex: 0 0 225px;
    border-left: 1px solid rgba(255, 255,255 ,0.2);
    display: flex;
}
.info-section__footer-wrap {
    margin: 0 auto;
}
.info-section__footer-title {
    color: #F2C055;
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.8px;
}
.info-section__footer-content {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 1.2px;
}

.divider-lg {
    display: block;
}
.divider-sm {
    display: none;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
}
@media screen and (max-width: 768px) {
    .info-section-wrap {
        margin-top: 160px;
    }
    .info-section {
        padding: 40px 24px;
        flex-direction: column;
        align-items: stretch;
    }
    .info-section__head {
        flex: 0 0 auto;
    }
    .info-section__head-title {
        font-size: 32px;
    }
    .info-section__head-subtitle {
        font-size: 14px;
    }
    .info-section__head-content {
        padding-bottom: 32px;
    }
    .info-section__body {
        flex: 0 0 auto;
        padding: 32px 0;
        border-left: none;
        border-top: 1px solid rgba(255, 255,255 ,0.2);
    }
    .info-section__body-wrap {
        margin: 0;
    }
    .info-section__body-time {
        flex-direction: column;
        align-items: flex-start;
    }
    .divider {
        margin: 30px 0 26px;
        position: relative;
    }
    .divider::after {
        content: 'until';
        color: #fff;
        opacity: 0.5;
        position: absolute;
        width: 40px;
        right: -48px;
        line-height: 24px;
        top: -13px;
        font-size: 16px;
    }
    .info-section__footer {
        flex: 0 0 auto;
        padding: 32px 0 0;
        border-left: none;
        border-top: 1px solid rgba(255, 255,255 ,0.2);
    }
    .info-section__footer-wrap { 
        margin: 0;
    }


    .divider-lg {
        display: none;
    }
    .divider-sm {
        display: block;
    }
}