
@media (min-width: 1280px) {
    ::-webkit-scrollbar-thumb {
        border-radius: 9999px;
    }
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e9ba55;
        border: 2px solid #000;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a88b41;
    }
}

