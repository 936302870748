.custom-modal .ant-modal-content {
    background-color: rgba(17,17,17);
    border-radius: 0;
    padding: 80px 120px;
    position: relative;
}
.custom-modal .ant-modal {
    width: 100% !important;
    max-width: 1280px;
}

.custom-modal .ant-modal-body {
    padding: 0;
}

.custom-modal .ant-modal-close {
    color: #fff;
    top: -40px;
    width: auto;
    height: auto;
}

@media (max-width: 768px) {
    .custom-modal .ant-modal {
        width: 100% !important;
    }
    .custom-modal .ant-modal-content {
        padding: 80px 12px;
    }
}