@import url(https://fonts.googleapis.com/css2?family=Prata&display=swap);
body {
  font-family: 'Prata',ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
  letter-spacing: 0.8px !important;
  background-color: #000 !important;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.term-modal .ant-modal-content {
    background-color: rgba(17,17,17);
    border-radius: 0;
    position: relative;
}
.term-modal .ant-modal {
    width: 100% !important;
    max-width: 1280px;
}

.term-modal .ant-modal-body {
    padding: 80px 120px;
    height: 76vh;
    overflow: auto;
}

.term-modal .ant-modal-close {
    color: #fff;
    top: -40px;
    width: auto;
    height: auto;
}

@media (max-width: 768px) {
    .term-modal .ant-modal {
        width: 100% !important;
    }
    .term-modal .ant-modal-body {
        padding: 80px 12px;
    }
}
.custom-modal .ant-modal-content {
    background-color: rgba(17,17,17);
    border-radius: 0;
    padding: 80px 120px;
    position: relative;
}
.custom-modal .ant-modal {
    width: 100% !important;
    max-width: 1280px;
}

.custom-modal .ant-modal-body {
    padding: 0;
}

.custom-modal .ant-modal-close {
    color: #fff;
    top: -40px;
    width: auto;
    height: auto;
}

@media (max-width: 768px) {
    .custom-modal .ant-modal {
        width: 100% !important;
    }
    .custom-modal .ant-modal-content {
        padding: 80px 12px;
    }
}
.info-section-wrap {
    margin-top: 80px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.info-section {
    width: 100%;
    padding: 51px 120px;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    z-index: 1;
}

.info-section__head {
    flex: 0 0 327px;
}
.info-section__head-title {
    font-size: 36px;
    line-height: 1.5;
    letter-spacing: 1.8px;
    font-weight: 400;
    color: #fff;
}
.info-section__head-contents {
    margin-top: 24px;
}
.info-section__head-subtitle {
    color: rgba(178, 178, 178);
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.7px;
}
.info-section__head-content {
    font-size: 18px;
    line-height: 2;
    letter-spacing: 0.7px;
}

.info-section__body {
    flex: 0 0 487px;
    border-left: 1px solid rgba(255, 255,255 ,0.2);
    display: flex;
    flex-direction: column;
}
.info-section__body-wrap {
    margin: 0 auto;
}
.info-section__body-title {
    color: #F2C055;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 2;
    margin-bottom: 0;
}
.info-section__body-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
    grid-gap: 24px;
    gap: 24px;
}

.time-item {
    display: flex;
    flex-direction: column;
}

.time-item__head {
    color: rgba(178, 178, 178);
    font-weight: 400;
    letter-spacing: 1.2px;
    font-size: 24px;
    line-height: 2;
}

.time-item__body {
    color: #fff;
    letter-spacing: 1.8px;
    font-size: 36px;
    line-height: 1.2;
}

.time-item__footer {
    font-weight: 400;
    letter-spacing: 0.7px;
    font-size: 14px;
    line-height: 2;
}

.info-section__footer {
    flex: 0 0 225px;
    border-left: 1px solid rgba(255, 255,255 ,0.2);
    display: flex;
}
.info-section__footer-wrap {
    margin: 0 auto;
}
.info-section__footer-title {
    color: #F2C055;
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.8px;
}
.info-section__footer-content {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 1.2px;
}

.divider-lg {
    display: block;
}
.divider-sm {
    display: none;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
}
@media screen and (max-width: 768px) {
    .info-section-wrap {
        margin-top: 160px;
    }
    .info-section {
        padding: 40px 24px;
        flex-direction: column;
        align-items: stretch;
    }
    .info-section__head {
        flex: 0 0 auto;
    }
    .info-section__head-title {
        font-size: 32px;
    }
    .info-section__head-subtitle {
        font-size: 14px;
    }
    .info-section__head-content {
        padding-bottom: 32px;
    }
    .info-section__body {
        flex: 0 0 auto;
        padding: 32px 0;
        border-left: none;
        border-top: 1px solid rgba(255, 255,255 ,0.2);
    }
    .info-section__body-wrap {
        margin: 0;
    }
    .info-section__body-time {
        flex-direction: column;
        align-items: flex-start;
    }
    .divider {
        margin: 30px 0 26px;
        position: relative;
    }
    .divider::after {
        content: 'until';
        color: #fff;
        opacity: 0.5;
        position: absolute;
        width: 40px;
        right: -48px;
        line-height: 24px;
        top: -13px;
        font-size: 16px;
    }
    .info-section__footer {
        flex: 0 0 auto;
        padding: 32px 0 0;
        border-left: none;
        border-top: 1px solid rgba(255, 255,255 ,0.2);
    }
    .info-section__footer-wrap { 
        margin: 0;
    }


    .divider-lg {
        display: none;
    }
    .divider-sm {
        display: block;
    }
}
@font-face {
    font-family: bluunext;
    src: url(/static/media/bluunext-bolditalic-webfont.a00ee8e3.woff);
}

.normal-border {
    position: relative;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 6px;
}

.normal-border:hover {
    /* transform: translateY(-8px); */
    /* border: none; */
    cursor: pointer;
}

.normal-border::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: 0.3s;
    background-color: rgba(255,255,255,0.1);
    z-index: -1;
}

.normal-border:hover::after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}

.normal-border__wrap {
    padding: 24px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 3px;
    border-image: url(/static/media/border.2b8a0a6c.svg) 3 3 round;
}

.primary-border {
    position: relative;
    transition: all 0.3s;
    border: 2px solid #F2C055;
}

.primary-border:hover {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    border: 2px solid transparent;
    cursor: pointer;
}

.primary-border::after {
    content: 'Open';
    font-size: 96px;
    line-height: 115px;
    font-family: bluunext;
    -webkit-text-stroke: 2px #282828;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-style: italic;
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: 0.3s;
    background-color: #1C1C1C;
    z-index: -1;
}

.primary-border:hover::after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}
[data-aos="custom-fade-up"] {
    -webkit-transform: translate(0, 80px);
            transform: translate(0, 80px);
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition: cubic-bezier(0,0,.2,1);
}

[data-aos="custom-fade-up"].aos-animate {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
}
.mint-modal .ant-modal-content {
  background-color: rgba(17,17,17);
  border-radius: 0;
  padding: 0;
  position: relative;
}
.mint-modal .ant-modal-body {
  padding: 80px 120px !important;
}
.mint-modal .ant-modal {
  width: 100% !important;
  max-width: 1280px;
}

.mint-modal .ant-modal-body {
  padding: 0;
}

.mint-modal .ant-modal-close {
  color: #fff;
  top: -40px;
  width: auto;
  height: auto;
}

@media (max-width: 768px) {
  .mint-modal .ant-modal {
    width: 100% !important;
  }
  .mint-modal .ant-modal-body {
      padding: 80px 12px !important;
      max-height: 76vh;
      overflow: auto;
  }
}


@media (min-width: 1280px) {
    ::-webkit-scrollbar-thumb {
        border-radius: 9999px;
    }
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #e9ba55;
        border: 2px solid #000;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a88b41;
    }
}


