@font-face {
    font-family: bluunext;
    src: url('../font/bluunext-bolditalic-webfont.woff');
}

.normal-border {
    position: relative;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 6px;
}

.normal-border:hover {
    /* transform: translateY(-8px); */
    /* border: none; */
    cursor: pointer;
}

.normal-border::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: 0.3s;
    background-color: rgba(255,255,255,0.1);
    z-index: -1;
}

.normal-border:hover::after {
    transform: scaleY(1);
}

.normal-border__wrap {
    padding: 24px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 3px;
    border-image: url('../images/border.svg') 3 3 round;
}

.primary-border {
    position: relative;
    transition: all 0.3s;
    border: 2px solid #F2C055;
}

.primary-border:hover {
    transform: translateY(-8px);
    border: 2px solid transparent;
    cursor: pointer;
}

.primary-border::after {
    content: 'Open';
    font-size: 96px;
    line-height: 115px;
    font-family: bluunext;
    -webkit-text-stroke: 2px #282828;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-style: italic;
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: 0.3s;
    background-color: #1C1C1C;
    z-index: -1;
}

.primary-border:hover::after {
    transform: scaleY(1);
}