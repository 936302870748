.mint-modal .ant-modal-content {
  background-color: rgba(17,17,17);
  border-radius: 0;
  padding: 0;
  position: relative;
}
.mint-modal .ant-modal-body {
  padding: 80px 120px !important;
}
.mint-modal .ant-modal {
  width: 100% !important;
  max-width: 1280px;
}

.mint-modal .ant-modal-body {
  padding: 0;
}

.mint-modal .ant-modal-close {
  color: #fff;
  top: -40px;
  width: auto;
  height: auto;
}

@media (max-width: 768px) {
  .mint-modal .ant-modal {
    width: 100% !important;
  }
  .mint-modal .ant-modal-body {
      padding: 80px 12px !important;
      max-height: 76vh;
      overflow: auto;
  }
}
